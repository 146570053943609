.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .title {
    margin: 0;
    margin-bottom: 12px;
    font-size: 36px;
    font-weight: 600;
  }

  .subtitle {
    margin: 0;
    font-size: 24px;
    font-weight: normal;
  }
}